import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const DescriptionHeader = () => {
  return (
    <div className="bio">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/profile-pic.png"
        width={50}
        height={50}
        quality={95}
        alt="Profile picture"
      />
      <p>
        Miscellaneous notes, observations, and ideas from the journey of learning to build interesting and useful
        software products.
      </p>
    </div>
  )
}

export default DescriptionHeader